<template>
  <!-- 作品管理 -->
  <div class="zpgl">
    <div class="row1">
      <span>
        <el-button
          style="margin-right: 10px"
          type="primary"
          v-for="(item, i) in stateList"
          :key="i"
          size="mini"
          :plain="model.isAudit !== item.id"
          @click="model.isAudit = item.id"
        >
          {{ item.name }}
        </el-button>
        <!-- <el-select   size="mini"></el-select> -->
      </span>
      <span class="right">
        <el-input
          size="mini"
          v-model="model.searchName"
          placeholder="搜索关键词"
          @input="search"
        ></el-input>
        <el-button size="mini" ref="aaa" style="margin-left: 10px"
          >批量管理</el-button
        >
      </span>
    </div>
    <div v-loading="loading" class="row2">
      <el-card class="row2-card">
        <!-- 行数据 -->
        <div
          class="item"
          @click="goEntry(item)"
          v-for="(item, i) in list"
          :key="i"
          :style="{ background: i % 2 !== 0 ? '#fff' : 'rgba(250,249,255,1)' }"
        >
          <div class="flex">
            <el-checkbox></el-checkbox>
            <div class="item-index">
              {{ i + 1 }}
            </div>
          </div>
          <!-- 剧本题目 -->
          <div class="item-title" style="width: 23%">
            《{{ item.scenarioName }}》
          </div>
          <!-- 剧本标签信息 -->
          <div class="item-message">
            <span
              >{{ item.styleStr.length ? `${item.styleStr[0]} /` : "" }}
            </span>
            {{ item.playNum }}人 / {{ item.chapterNum }}幕 /
            {{ item.predictTime }}分钟
          </div>
          <div class="item-other flex">
            <!-- 剧本上传日期 -->
            <div class="item-date" style="width: 20%">
              {{ item.createTime }}
            </div>
            <!-- 审核按钮 -->
            <div class="flex-end flex-1">
              <div
                @click.stop="scenarioAudit(item)"
                v-hasAuth="'scenario-audit'"
                class="item-audit"
                :class="
                  item.isAudit !== 2 ? 'item-check-true' : 'item-check-false'
                "
              >
                {{ item.isAudit !== 2 ? "过审" : "不过审" }}
              </div>
              <div
                v-hasAuth="'scenario-audit'"
                style="color: #7f76bd; font-size: 14px; margin: 0 10px"
                @click.stop="moreBtn(item)"
              >
                更多
              </div>
              <div class="item-state">
                <div
                  class="state"
                  :style="{ background: returnState(item.isAudit).color }"
                >
                  {{ returnState(item.isAudit).name }}
                </div>
              </div>
              <div class="item-state" style="width: 40px; margin-left: 3px">
                <div
                  class="state"
                  :style="{
                    background:
                      whetherPlayMap[
                        item.isAudit === 1 ? 0 : item.whetherPlay ? 1 : 2
                      ].color,
                  }"
                >
                  {{
                    whetherPlayMap[
                      item.isAudit === 1 ? 0 : item.whetherPlay ? 1 : 2
                    ].name
                  }}
                </div>
              </div>
              <!-- 发售剧本 -->
              <div
                v-hasAuth="'scenario-audit'"
                @click.stop="toScenario(item)"
                class="item-sale"
              >
                发售剧本>
              </div>
            </div>
          </div>
        </div>
      </el-card>
    </div>
    <div class="row3">
      <el-pagination
        :total="count"
        :current-page="model.page"
        :page-size="model.limit"
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        layout="prev, pager, next"
      ></el-pagination>
    </div>
    <!-- 剧本更多设置弹窗 -->
    <el-dialog title="提示" :visible.sync="moreSetDailog" width="768px">
      <div slot="title" style="text-align: center">
        <span style="background: #eceafa; padding: 5px">{{
          moreSetTitle
        }}</span>
      </div>
      <div class="flex flex-ac m-b-5">
        <div class="title">游戏状态</div>
        <myswitch
          v-model="moreSetModel.whetherPlay"
          leftText="可玩"
          rightText="禁玩"
          :leftValue="1"
          :rightValue="0"
        />
        <span class="remark-text font-s14 m-l-10"
          >选择“禁玩”时全部用户都不能创建房间</span
        >
      </div>
      <div class="flex flex-ac m-b-5">
        <div class="title">禁玩时文本</div>
        <el-input
          size="mini"
          style="width: 200px"
          v-model="moreSetModel.forbidPlayText"
          placeholder="请输入文本"
        ></el-input>
        <span class="remark-text font-s14 m-l-10"
          >当没有售价（剧本商品）时，向全部用户显示</span
        >
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="moreSetDailog = false">取 消</el-button>
        <el-button type="primary" @click="changePlaySave">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { debounce } from "@tools";
export default {
  data() {
    this.whetherPlayMap = [
      {
        name: "/",
        color: "rgba(215, 213, 223, 1)",
      },
      {
        name: "可玩",
        color: "rgba(139, 209, 203, 1)",
      },
      {
        name: "禁玩",
        color: "rgba(248, 205, 227, 1)",
      },
    ];
    return {
      loading: false,
      moreSetDailog: false,
      content: "",
      model: {
        isAudit: 0,
        searchName: "",
        time: "",
        page: 1,
        limit: 50,
      },
      moreSetTitle: "",
      moreSetModel: {
        id: "",
        forbidPlayText: "敬请期待", // 禁玩文本
        whetherPlay: 1,
      },
      stateList: [
        {
          id: 0,
          name: "全部",
        },
        {
          id: 1,
          name: "草稿箱",
          color: "rgba(215, 213, 223, 1)",
        },
        {
          id: 2,
          name: "已审核",
          color: "rgba(139, 209, 203, 1)",
        },
        {
          id: 3,
          name: "未通过",
          color: "rgba(248, 205, 227, 1)",
        },
      ],
      count: 0,
      list: [],
    };
  },
  computed: {},
  created() {
    this.getScenarioAll();
    // !this.$route.params.refresh && this.$store.dispatch("getScenarioList");
  },
  methods: {
    search() {
      this.model.page = 1;
      this.getScenarioAll();
    },
    moreBtn(e) {
      this.moreSetTitle = e.scenarioName;
      this.moreSetDailog = true;
      const { whetherPlay, id, forbidPlayText } = e;
      this.moreSetModel = {
        whetherPlay: whetherPlay ? 1 : 0,
        id,
        forbidPlayText,
      };
    },
    async changePlaySave() {
      const moreSetModel = JSON.parse(JSON.stringify(this.moreSetModel));
      moreSetModel.whetherPlay = moreSetModel.whetherPlay ? true : false;
      this.getRequest.changePlayStatus(moreSetModel);
      this.moreSetDailog = false;
      this.getScenarioAll();
    },
    getScenarioAll: debounce(async function () {
      this.loading = true;
      try {
        const { data, count } = await this.getRequest.getScenarioAll(
          this.model
        );
        this.count = count;
        this.list = data;
      } finally {
        this.loading = false;
      }
    }, 200),
    handleSizeChange(size) {
      this.model.size = size;
      this.getScenarioAll();
    },
    handlePageChange(page) {
      this.model.page = page;
      this.getScenarioAll();
    },
    returnState(state) {
      let re = {};
      this.stateList.forEach((res) => {
        if (res.id === state) {
          re = {
            name: res.name,
            color: res.color,
          };
        }
      });
      return re;
    },
    toScenario(item) {
      this.$router.push({
        name: "shoppingGoods",
        params: {
          isScenario: true,
          scenarioName: item.scenarioName,
        },
      });
    },
    goEntry(item) {
      localStorage.setItem("scenarioId", item.id);
      const isEdit =
        this.$store.state.permissions.scenario &&
        this.$store.state.permissions.scenario.edit
          ? true
          : false;
      sessionStorage.setItem("isEdit", JSON.stringify(isEdit));
      sessionStorage.setItem("privateFlag", JSON.stringify(item.privateFlag));

      const showTipFlag = item.isAudit === 2 && item.whetherPlay === false;
      sessionStorage.setItem("showTipFlag", JSON.stringify(showTipFlag));
      sessionStorage.scenarioName = item.scenarioName
      this.$router.push({ name: "entry"});
    },
    scenarioAudit(item) {
      let { id, isAudit } = item;
      isAudit = isAudit === 2 ? 3 : 2;
      this.$confirm(`是否${isAudit === 2 ? "上架" : "下架"}剧本?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        await this.getRequest.scenarioAudit({ id, isAudit });
        this.getScenarioAll();
      });
    },
  },
};
</script>

<style lang='scss' scoped>
.zpgl {
  width: 100%;
  height: 100%;
  .row1 {
    display: flex;
    justify-content: space-between;
    .right {
      display: flex;
    }
  }
  .row2 {
    margin-top: 10px;
    width: 100%;
    height: calc(100% - 58px);
    .row2-card {
      padding: 10px 0;
      height: 100%;
      overflow-y: scroll;
      .item {
        color: rgba(51, 51, 51, 1);
        height: 24px;
        line-height: 24px;
        font-size: 14px;
        border-radius: 4px;
        margin-top: 6px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        .item-index {
          margin-left: 10px;
          min-width: 18px;
          height: 18px;
          border-radius: 4px;
          border: 2px solid rgba(197, 192, 224, 1);
          color: rgba(197, 192, 224, 1);
          font-size: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        }
        .item-title {
          margin-left: 10px;
          width: 25%;
        }
        .item-message {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-left: 10px;
          width: 23%;
        }
        .item-other {
          width: 57%;
          display: flex;
          justify-content: space-around;
        }
        .item-audit {
          height: 20px;
          width: 80px;
          line-height: 20px;
          text-align: center;
          border-radius: 4px;
          font-size: 14px;
        }
        .item-check-true {
          background: #7f76bd;
          border-radius: 4px;
          color: #fff;
        }
        .item-check-false {
          color: #666;
          background: #ffffff;
          border: 1px solid #c5c0e0;
        }
        .item-date {
          color: rgba(153, 153, 153, 1);
          margin: 0 10px;
          width: 30%;
          text-align: center;
        }
        .item-sale {
          // width: 96px;
          height: 24px;
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #7f76bd;
          line-height: 24px;
          margin-left: 15px;
          text-align: right;
        }
        .item-state {
          margin-left: 15px;
          width: 54px;
          display: flex;
          justify-content: flex-end;
          .state {
            font-size: 14px;
            width: 54px;
            height: 20px;
            border-radius: 4px;
            color: #fff;
            text-align: center;
            line-height: 20px;
          }
        }
      }
    }
  }
  .row3 {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  .title {
    margin-right: 20px;
    width: 96px;
    height: 24px;
    background: #eceafa;
    border-radius: 4px;
    line-height: 24px;
    text-align: center;
  }
}
</style>